<template>
  <v-card class="rounded-0">
    <v-app-bar dense dark :class="tableBarColor">
      <v-app-bar-title
        v-if="$vuetify.breakpoint.smAndUp"
        class="font-weight-light text-h5"
        >{{ title }} - {{ items.length }}</v-app-bar-title
      >
      <v-app-bar-title v-else class="font-weight-bold text-h5 pr-4">{{
        items.length
      }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        autocomplete="off"
        hide-details
        clearable
        color="white"
        outlined
        dense
        single-line
        placeholder="Cerca"
        v-model="search"
      >
        <template slot="prepend">
          <v-icon color="white" size="30">mdi-magnify</v-icon>
        </template>
      </v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$vuetify.breakpoint.smAndUp"
        outlined
        tile
        class="mr-4"
        color="white"
        @click="chiudi"
      >
        chiudi
      </v-btn>
      <v-btn v-else icon @click="chiudi">
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-data-table
      :headers="headers"
      :items="items"
      :dense="dense"
      hide-default-footer
      :items-per-page="-1"
      mobile-breakpoint="0"
      :height="height"
      :search="search"
      fixed-header
      multi-sort
    >
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  name: "DatatableFull",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    tableBarColor: {
      type: String,
      default: "bg-gradient-info",
    },
  },
  computed: {
    height() {
      const height = "100vh";
      return `calc(${height} - 48px)`;
    },
  },
  data() {
    return {
      dialog: false,
      search: "",
    };
  },
  methods: {
    chiudi() {
      this.$emit("chiudi");
    },
  },
};
</script>
