<template>
  <v-fade-transition mode="out-in">
    <router-view></router-view>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {},

  data: () => ({}),
  computed: {
    ...mapGetters({
      isLogged: "user/isLogged",
    }),
    mainClass() {
      return this.isLogged ? "" : "auth-pages";
    },
  },
  created() {
    this.checkAuth();
    this.$store.dispatch("multisettings/bindMultisettings", {
      projectId: "milano",
    });
    this.readSettings();
  },
  methods: {
    ...mapActions({
      checkAuth: "multisettings/authAction",
      readSettings: "settings/bindSettings",
      readAllowed: "settings/bindAllowed",
    }),
  },
};
</script>
<style>

.pill-button {
  border-radius: 50px;
}
.shadow-text {
  text-shadow: 1px 1px 12px black;
}
.shadow-text-full {
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000;
}
.shadow-text-w {
  text-shadow: 1px 1px 2px #fff;
}
.shadow-text-full-w {
  text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff,
    -1px -1px 0 #fff;
}
.centered-input input {
  text-align: center;
}
.black-transparent {
  color: rgba(0, 0, 0, 0.5);
}
.right-input input {
  text-align: right;
  margin-right: 12px;
}
.centered-select .v-select__selection {
  width: 100%;
  justify-content: center;
  text-align: center;
}
.huge-headero {
  font-size: 3.975rem !important; /* from .text-h4 */
  line-height: 4.175rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.big-headero {
  font-size: 3.575rem !important; /* from .text-h4 */
  line-height: 3.975rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.regular-headero {
  font-size: 2.75rem !important; /* from .text-h4 */
  line-height: 3rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.small-headero {
  font-size: 1.75rem !important; /* from .text-h4 */
  line-height: 2.25rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.tiny-headero {
  font-size: 1.15rem !important; /* from .text-h4 */
  line-height: 1.75rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.huge-header {
  font-size: 3rem !important; /* from .text-h4 */
  line-height: 3.5rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.big-header {
  font-size: 2.8rem !important; /* from .text-h4 */
  line-height: 3rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.regular-header {
  font-size: 2.5rem !important; /* from .text-h4 */
  line-height: 2.75rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.small-header {
  font-size: 1.75rem !important; /* from .text-h4 */
  line-height: 2.25rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}
.tiny-header {
  font-size: 1.15rem !important; /* from .text-h4 */
  line-height: 1.75rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
}

.ubuntu-light {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ubuntu-regular {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ubuntu-medium {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ubuntu-bold {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.ubuntu-light-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.ubuntu-regular-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.ubuntu-medium-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.ubuntu-bold-italic {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.bkr-bold {
  font-family: Novat Bold, Ubuntu, sans-serif;
}
.bkr-medium {
  font-family: Novat Medium, Ubuntu, sans-serif;
}
.custom-header {
  font-size: 2.125rem !important; /* from .text-h4 */
  line-height: 2.5rem; /* from .text-h4 */
  letter-spacing: 0.0073529412em !important; /* from .text-h4 */
  font-weight: 400; /* from .text-h4 */
}
.bkr-color {
  color: #00755e;
}
.white-bg {
  background-color: #ffffff;
}
</style>
<style lang="scss">
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

// Bonus "expand" variant
.v-input--expand .v-input__slot {
  //justify-content: space-between;
  .v-label {
    display: block;
    flex: 1;
  }
}
</style>
