import { auth } from "@/firebase/init";
import router from "./../../router";
import { firestoreAction } from "vuexfire";
import { db, union } from "@/firebase/init";
import Swal from "sweetalert2/dist/sweetalert2";
import { dynamicSort } from "@/utilities/helpers";
// import dayjs from "dayjs";
const project = "edenredclub01";
const namespaced = true;
const state = {
  id: null,
  role: "notLogged",
  email: null,
  isLogged: false,
  personalData: {},
  initial: {},
  accompagnatore: {},
};

const mutations = {
  SET_USER_AUTH: (state, payload) => {
    state.id = payload.id;
    state.role = payload.role;
    state.email = payload.email;
    state.isLogged = payload.isLogged;
  },
  UPDATE_PERSONAL_DATA: (state, payload) => {
    state.personalData = payload;
  },
  CONFIRM_PARTY: (state) => {
    state.personalData.confirmed_party = new Date().toISOString();
  },
  SET_INITIAL: (state, payload) => {
    state.initial = JSON.parse(JSON.stringify(payload));
  },
  UPDATE_INITIAL: (state) => {
    state.initial = JSON.parse(JSON.stringify(state.personalData));
  },
  RESET_PERSONAL_DATA: (state) => {
    state.personalData = JSON.parse(JSON.stringify(state.initial));
  },
  SET_PERSONAL_DATA: (state, payload) => {
    payload.lista_acc = payload.lista_acc.sort(dynamicSort("nascita_data"));
    state.personalData = payload;
  },
  SET_ACCOMPAGNATORE: (state, payload) => {
    state.accompagnatore = payload;
  },
  ELIMINA_ACCOMPAGNATORE: (state, payload) => {
    state.personalData.lista_acc.splice(payload, 1);
  },
  RESET_ACCOMPAGNATORE: (state) => {
    state.accompagnatore = {
      id: null,
      agenda: [],
      titolo: null,
      genere: null,
      nome: null,
      cognome: null,
      codice_fiscale: null,
      parentela: null,
      nascita_stato: null,
      nascita_comune: null,
      nascita_provincia: null,
      nascita_data: null,
      cellulare: null,
      doc_numero: null,
      doc_data: null,
      doc_scad: null,
      business: null,
      hotel: null,
      alimentari_allergie: [],
      alimentari_preferenze: [],
      alimentari_note: "",
      volo: null,
    };
  },
};
const actions = {
  pushToGalaList: async ({ dispatch, rootGetters }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    let gala = rootGetters["user/listaGala"];
    let turno = rootGetters["user/turno"];
    let docRef = db.doc(`projects/${project}/gala/galaLW${turno}`);
    try {
      await docRef.update({
        winners: union(gala),
      });
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  authAction(context) {
    auth.onAuthStateChanged((user) => {
      if (user) {
        let role;
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            role = "admin";
          } else if (idTokenResult.claims.editor) {
            role = "editor";
          } else role = "user";
          context.commit("SET_USER_AUTH", {
            id: user.uid,
            email: user.email,
            role: role,
            isLogged: true,
          });
          if (role === "admin" || role === "editor") {
            context.dispatch("bindSuperUserData");
            context.dispatch("participants/bindParticipantsData", null, {
              root: true,
            });
            context.dispatch("agenda/bindEventi", null, {
              root: true,
            });
            context.dispatch("gala/bindGala", null, { root: true });
          } else {
            context.dispatch("bindUserData");
            context.dispatch("agenda/bindEventi", null, {
              root: true,
            });
            context.dispatch("gala/bindGala", null, { root: true });
          }
        });
      } else {
        context.commit("SET_USER_AUTH", {
          id: null,
          email: null,
          role: "notLogged",
          isLogged: false,
          personalData: {},
        });
        context.dispatch("participants/unbindParticipantsData", null, {
          root: true,
        });
        context.dispatch("agenda/unbindEventi", null, {
          root: true,
        });
        context.dispatch("unbindUserData");
        context.dispatch("unbindSuperUserData");
        context.dispatch("gala/unbindGala", null, { root: true });
      }
    });
  },
  setUserAuth: (context, userAuthData) => {
    context.commit("SET_USER_AUTH", userAuthData);
  },
  bindUserData: firestoreAction((context) => {
    return Promise.all([
      context.bindFirestoreRef(
        "personalData",
        db
          .collection("projects")
          .doc(project)
          .collection("users")
          .doc(context.state.email)
      ),
      context.bindFirestoreRef(
        "initial",
        db
          .collection("projects")
          .doc(project)
          .collection("users")
          .doc(context.state.email)
      ),
    ]);
  }),
  unbindUserData: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef(["personalData", "initial"]);
  }),
  bindSuperUserData: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "personalData",
      db
        .collection("projects")
        .doc(project)
        .collection("superusers")
        .doc(context.state.email)
    );
  }),
  unbindSuperUserData: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("personalData");
  }),
  login: (context, user) => {
    return auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then((userCredentials) => {
        userCredentials.user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            router.push("/admin/reports");
          } else if (idTokenResult.claims.editor) {
            router.push("/admin/reports");
          } else {
            router.push("/user/home");
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            err.code === "auth/wrong-password"
              ? "Password errata"
              : err.code === "auth/too-many-requests"
              ? "Troppi tentativi non riusciti, riprova tra qualche minuto"
              : "Errore generico: " + err.code,
          confirmButtonText: "Riprova",
          footer:
            'Se il problema persiste,&nbsp;<a href="mailto:viaggi@mediolanum.it?subject=Mail%20non%20riconosciuta%20Winners2024">scrivi a viaggi@mediolanum.it</a>',
        });
      });
  },
  logout: () => {
    auth.signOut().then(() => {
      router.push("/");
    });
  },
  setUser: ({ commit }, payload) => {
    commit("SET_PERSONAL_DATA", payload);
  },
  setAccompagnatore: ({ commit }, payload) => {
    commit("SET_ACCOMPAGNATORE", payload);
  },
  resetAccompagnatore: ({ commit }) => {
    commit("RESET_ACCOMPAGNATORE");
  },
  eliminaAccompagnatore: ({ commit, dispatch }, payload) => {
    commit("ELIMINA_ACCOMPAGNATORE", payload);
    dispatch("updateUser");
  },
  inserisciAcc: ({ commit, dispatch, state }) => {
    const accId = db.collection(`projects/${project}/users`).doc();
    const acc = { ...state.accompagnatore, id: accId.id, agenda: [] };
    let winner = state.personalData;
    if (winner.lista_acc) {
      winner.lista_acc.push(acc);
    } else winner.lista_acc = [acc];
    commit("SET_PERSONAL_DATA", winner);
    dispatch("updateUser");
    commit("RESET_ACCOMPAGNATORE");
  },
  salvaAccompagnatore: ({ commit, dispatch, state }, payload) => {
    let winner = state.personalData;
    winner.lista_acc[payload] = state.accompagnatore;
    commit("SET_PERSONAL_DATA", winner);
    dispatch("updateUser");
    commit("RESET_ACCOMPAGNATORE");
  },
  resetUser: ({ commit, dispatch }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    commit("RESET_PERSONAL_DATA");
    dispatch("userInterface/stopWorking", null, { root: true });
  },
  updateUser: async ({ state, dispatch }) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db
        .doc(`projects/${project}/users/${state.email}`)
        .set(state.personalData);
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  forceClearAgenda: async ({ state, dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    let user = state.personalData;
    if (payload.id === user.email) {
      user.agenda = [];
    } else {
      user.lista_acc.forEach((acc) => {
        if (acc.id === payload.id) {
          acc.agenda = [];
        }
      });
    }
    dispatch("setUser", user);
    dispatch("updateUser");
    try {
      await db.doc(`projects/${project}/users/${state.email}`).set(user);
      dispatch("userInterface/stopWorking", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("userInterface/stopWorking", null, { root: true });
    }
  },
  addUser: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db.doc(`projects/${project}/users/${payload.email}`).set(payload);
      dispatch("endLoading", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("endLoading", null, { root: true });
    }
  },
  removeUser: async ({ dispatch }, payload) => {
    dispatch("userInterface/startWorking", null, { root: true });
    try {
      await db.doc(`projects/${project}/users/${payload}`).delete();
      dispatch("endLoading", null, { root: true });
    } catch (error) {
      console.log(error);
      dispatch("endLoading", null, { root: true });
    }
  },
};

const getters = {
  user: (state) => {
    return state.personalData;
  },
  isBoss: (state) => {
    if (state.email === "andrea.disanto@gmail.com") return true;
    return false;
  },
  getAccompagnatore: (state) => {
    return state.accompagnatore;
  },
  email: (state) => {
    return state.email;
  },
  role: (state) => {
    return state.role;
  },
  isLogged: (state) => {
    return state.isLogged;
  },
  initials: (state) => {
    if (state.personalData) {
      if (state.personalData.nome && state.personalData.cognome) {
        return state.personalData.nome[0] + state.personalData.cognome[0];
      } else return "ND";
    } else return "ND";
  },
  
  nominativo: (state) => {
    let nominativo = "";
    if (state.personalData) {
      if (state.personalData.nome) nominativo = state.personalData.nome;
      if (state.personalData.cognome)
        nominativo += " " + state.personalData.cognome;
    }
    if (state.role) {
      if (state.role === "admin") {
        return nominativo ? nominativo : "Admin";
      }
      if (state.role === "editor") {
        return nominativo ? nominativo : "Suoervisor";
      } else {
        return nominativo ? nominativo : "Utente";
      }
    } else return "unknown";
  },
  isGiovanni: (state) => {
    if (state.personalData) {
      if (state.personalData.isGiovanni || state.role === "admin") {
        return true;
      } else return false;
    } else return false;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
