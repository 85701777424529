import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import it from "vuetify/lib/locale/it";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#fb0101",
        secondary: "#2D96A5",
        accent: "#B7C842",
        error: "#D82B36",
        info: "#CF4824",
        success: "#4CAF50",
        warning: "#fb8c00",
      },
    },
  },
  lang: {
    locales: { it },
    current: "it",
  },
});
