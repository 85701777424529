import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";
import user from "./modules/user";
// import participants from "./modules/participants";
import settings from "./modules/settings";
import multisettings from "./modules/multisettings";
import userInterface from "./modules/userInterface";
// import agenda from "./modules/agenda";
// import gala from "./modules/gala";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {
    ...vuexfireMutations,
  },
  actions: {},
  modules: {
    user,
    // agenda,
    // participants,
    // gala,
    settings,
    multisettings,
    userInterface,
  },
});
