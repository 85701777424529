<template>
  <v-card class="rounded-0">
    <v-app-bar dense dark :class="tableBarColor">
      <h2 v-if="$vuetify.breakpoint.smAndUp" class="font-weight-light text-h5">
        {{ subtitle }} - {{ title }}
      </h2>
      <h2 v-else class="font-weight-bold text-h5 pr-4">{{ title }}</h2>
      <v-spacer></v-spacer>
      <v-btn icon @click="modGroupBy" elevation="4" small class="mr-4">
        <v-avatar color="white" size="30">
          <v-icon color="primary">{{
            grouping
              ? "mdi-unfold-more-horizontal"
              : "mdi-unfold-less-horizontal"
          }}</v-icon>
        </v-avatar>
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        autocomplete="off"
        ref="trova"
        hide-details
        clearable
        color="white"
        outlined
        dense
        single-line
        placeholder="Cerca"
        v-model="search"
        tabindex="-1"
        clear-icon="mdi-close-circle"
      >
        <template slot="prepend">
          <v-icon @click="passFocus" color="white" size="30">mdi-magnify</v-icon>
        </template>
      </v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$vuetify.breakpoint.smAndUp"
        outlined
        tile
        class="mr-4"
        color="white"
        @click="chiudi"
      >
        chiudi
      </v-btn>
      <v-btn v-else icon @click="chiudi">
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-data-table
      :headers="headers"
      :items="items"
      :dense="dense"
      hide-default-footer
      :items-per-page="-1"
      mobile-breakpoint="0"
      :height="height"
      :search="search"
      fixed-header
      multi-sort
      item-key="itemKey"
      show-group-by
      :group-by="grouping"
      :item-class="(item) => (item.presenza ? 'presente' : 'assente')"
    >
      <template v-slot:[`item.nome`]="{ item }">
        <div
          @dblclick.prevent="togglepresenza(item)"
          @contextmenu.prevent="togglepresenza(item)"
          class="text-h5"
        >
          <b>{{ item.nome }}</b>
        </div>
      </template>
      <template v-slot:[`item.cognome`]="{ item }">
        <div
          @dblclick.prevent="togglepresenza(item)"
          @contextmenu.prevent="togglepresenza(item)"
          class="text-h5"
        >
          <b>{{ item.cognome }}</b>
        </div>
      </template>
      <template v-slot:[`item.presenza`]="{ item }">
        <v-chip :color="item.presenza ? 'success' : 'error'" dark>{{
          item.presenza ? "Presente" : "Assente"
        }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.itemKey === item.email"
          @click="openDialogAcc(item)"
          size="24"
          color="primary"
          >mdi-plus-circle</v-icon
        >
        <v-icon v-else size="24" @click="deleteItem(item)" color="error"
          >mdi-delete-forever</v-icon
        >
      </template>
    </v-data-table>
    <v-dialog v-model="dialogAddAcc" max-width="400">
      <v-card>
        <v-app-bar dense dark :class="tableBarColor">
          <v-spacer></v-spacer>
          <v-app-bar-title class="font-weight-light text-h5"
            >{{ itemToEdit.nome ? itemToEdit.nome.toUpperCase() : "" }}
            {{
              itemToEdit.cognome ? itemToEdit.cognome.toUpperCase() : ""
            }}</v-app-bar-title
          >
          <v-spacer></v-spacer>
        </v-app-bar>
        <v-card-text class="pb-0">
          <h3 class="text-h5 text-center py-4">Vuoi aggiungere un ospite?</h3>
          <v-text-field
            v-model="itemToAdd.nome"
            label="Nome"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="itemToAdd.cognome"
            label="Cognome"
            outlined
            dense
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-pill"
            color="primary"
            :disabled="!itemToAdd.nome || !itemToAdd.cognome"
            @click="addOspite"
            >aggiungi</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="rounded-pill"
            @click="chiudiAddAcc"
            >annulla</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  name: "DatatableLive",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
      default: "Subtitle",
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dense: {
      type: Boolean,
      default: false,
    },
    tableBarColor: {
      type: String,
      default: "bg-gradient-info",
    },
  },
  computed: {
    height() {
      const height = "100vh";
      return `calc(${height} - 48px)`;
    },
  },
  data() {
    return {
      dialog: false,
      dialogAddAcc: false,
      search: "",
      itemToEdit: {},
      itemToAdd: {},
      grouping: undefined,
    };
  },
  methods: {
    chiudi() {
      this.$emit("chiudi");
    },
    modGroupBy() {
      this.grouping = this.grouping === undefined ? "presenza" : undefined;
    },
    chiudiAddAcc() {
      this.dialogAddAcc = false;
      this.itemToAdd = {};
      this.itemToEdit = {};
    },
    addOspite() {
      this.itemToEdit.ospiti.push(this.itemToAdd);
      this.$emit("addOspite", this.itemToEdit);
      this.chiudiAddAcc();
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
    backgroundColor(item) {
      return item.presenza ? "presente" : "assente";
    },
    chooseAction(item) {
      if (item.itemKey === item.email) this.openDialogAcc(item);
      else this.deleteItem(item);
    },
    openDialogAcc(item) {
      let guest = this.$store.getters["multisettings/getInvitatoByEmail"](
        item.email
      );
      this.itemToEdit = JSON.parse(JSON.stringify(guest));
      this.dialogAddAcc = true;
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: "Non parteciperà?",
          html: `<b>${item.nome} ${item.cognome}</b><br><br>Vuoi rimuovere questo ospite?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#fb0101",
          cancelButtonColor: "#6c757d",
          confirmButtonText: "Si, rimuovi",
          cancelButtonText: "Annulla",
        })
        .then((result) => {
          if (result.value) {
            this.deleteItemConfirmed(item);
          }
        });
    },
    deleteItemConfirmed(item) {
      this.$emit("deleteItem", item);
    },
    passFocus() {
      this.$refs.trova.focus();
    },
    togglepresenza(item) {
      // let message = "";
      // if (item.itemKey === item.email)
      //   message = `${item.nome} ${item.cognome} <br> <b>invitato</b> <br> <br> ${item.ordinatore}`;
      // else
      //   message = `${item.nome} ${item.cognome} <br> <b>ospite n ${
      //     item.index + 1
      //   } di ${item.pax - 1}</b> <br><br> ${item.ordinatore}`;
      // this.$swal.fire({
      //   title: "attivato",
      //   html: message,
      //   icon: "success",
      //   confirmButtonColor: "#fb0101",
      // });
      this.$emit("togglepresenza", item);
    },
    alert(item) {
      console.log(item);
      this.$swal.fire({
        title: "Attenzione",
        text: "Evento concluso",
        icon: "warning",
        confirmButtonColor: "#d33",
        confirmButtonText: "ok",
      });
    },
  },
};
</script>
<style>
.presente {
  background-color: #e5f4d5 !important;
}
.assente {
  background-color: rgb(250, 250, 250);
}
</style>
