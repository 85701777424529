<template>
  <v-app>
    <base-working></base-working>
    <v-main class="auth-pages">
      <div class="position-relative min-vh-100" :style="background">
        <span v-if="false" class="mask bg-gradient-default opacity-1"></span>
        <v-container
          fluid
          class="fill-height"
          style="
            background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
          "
        >
          <v-row class="min-vh-100">
            <v-fade-transition mode="out-in">
              <router-view></router-view>
            </v-fade-transition>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "AuthBasicLayout",
  components: {},
  computed: {
    background() {
      if (this.$route.name === "Confirmation") {
        if (this.$vuetify.breakpoint.smAndUp) {
          return `background-image:  url(${require("@/assets/" +
            this.project +
            "/outro.jpg")}); background-size: cover; background-position: 50%;`;
        }
        else return `background-image:  url(${require("@/assets/" +
          this.project +
          "/outrosm.jpg")}); background-size: cover; background-position: 50%;`;
      }
      return `background-image:  url(${require("@/assets/" +
        this.project +
        "/authbg.jpg")}); background-size: cover; background-position: 50%;`;
    },
  },
  data() {
    return {
      project: this.$route.params.project,
    };
  },
  methods: {},
};
</script>
