import { firestoreAction } from "vuexfire";
import { db, auth, iscriviMilano, remarr, inviaMailHtml } from "@/firebase/init";
import { mailReminderHtml } from "@/utilities/mailTemplates";
import Swal from "sweetalert2/dist/sweetalert2";
import router from "./../../router";
import {
  toEdenredInvitato,
  toEdenredGuest,
  toEdenredGlobal,
  toEdenredLive,
} from "@/utilities/converters.js";
const project = "milano";
const namespaced = true;
const state = {
  settings: {},
  id: null,
  role: "notLogged",
  email: null,
  isLogged: false,
  guests: [],
  reserved: {},
};
const mutations = {
  SET_SETTINGS: (state, payload) => {
    state.settings = payload;
  },
  SET_USER_AUTH: (state, payload) => {
    state.id = payload.id;
    state.role = payload.role;
    state.email = payload.email;
    state.isLogged = payload.isLogged;
  },
};
const actions = {
  setSettings({ commit }, payload) {
    commit("SET_SETTINGS", payload);
  },
  logout: () => {
    // auth.signOut();
    auth.signOut().then(() => {
      router.push("/milano/adminlogin");
    });
  },
  login: (context, user) => {
    return auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then((userCredentials) => {
        userCredentials.user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            router.push(`/${user.project}/admin`);
          } else if (idTokenResult.claims.editor) {
            if (auth.currentUser.email.includes("operatore")) {
              router.push(`/${user.project}/admin/accessi`);
            } else {
              router.push(`/${user.project}/admin`);
            }
          } else {
            router.push(`/${user.project}`);
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            err.code === "auth/wrong-password"
              ? "Wrong password"
              : err.code === "auth/too-many-requests"
              ? "Too many requests. Try again later"
              : "Generic error: " + err.code,
          confirmButtonText: "Try again",
          footer:
            'Il problema persiste? Scrivi a &nbsp;<a href="mailto:segreteria@edenredclub.it?subject=Mail%20non%20autorizzata">segreteria@edenredclub.it</a>',
        });
      });
  },
  authAction(context) {
    auth.onAuthStateChanged((user) => {
      if (user) {
        let role;
        user.getIdTokenResult().then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            role = "admin";
          } else if (idTokenResult.claims.editor) {
            role = "editor";
          } else role = "user";
          context.commit("SET_USER_AUTH", {
            id: user.uid,
            email: user.email,
            role: role,
            isLogged: true,
          });
          if (role === "admin" || role === "editor") {
            context.dispatch("bindGuestsData");
            context.dispatch("bindReserved");
          }
        });
      } else {
        context.commit("SET_USER_AUTH", {
          id: null,
          email: null,
          role: "notLogged",
          isLogged: false,
        });
        context.dispatch("unbindGuestsData");
        context.dispatch("unbindReserved");
      }
    });
  },
  bindGuestsData: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "guests",
      db.collection("multiprojects").doc(project).collection("guests")
    );
  }),
  unbindGuestsData: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("guests");
  }),
  bindReserved: firestoreAction((context) => {
    return context.bindFirestoreRef(
      "reserved",
      db
        .collection("multiprojects")
        .doc(project)
        .collection("reserved")
        .doc("registered")
    );
  }),
  unbindReserved: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("reserved");
  }),
  bindMultisettings: firestoreAction((context, payload) => {
    return context.bindFirestoreRef(
      "settings",
      db
        .collection("multiprojects")
        .doc(payload.projectId)
        .collection("settings")
        .doc("settings")
    );
  }),
  unbindMultisettings: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef("settings");
  }),
  updateSettingsFromState: async ({ state }) => {
    try {
      await db
        .doc(`multiprojects/${project}/settings/settings`)
        .set(state.settings, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },
  backupData: async ({ state }) => {
    try {
      await db
        .doc(`multiprojects/${project}/backup/allguests`)
        .set({ guests: state.guests });
    } catch (error) {
      console.log(error);
    }
  },
  eraseDb: async ({ state }) => {
    state.guests.forEach(async (guest) => {
      try {
        await db.doc(`multiprojects/${project}/guests/${guest.email}`).delete();
      } catch (error) {
        console.log(error);
      }
    });
  },
  updatePresenza: async (state, payload) => {
    let guest = state.guests.find((guest) => guest.email === payload.email);
    if (payload.ospite === -1) {
      guest.presenza = payload.presenza;
      guest.presenzaSetAt = new Date().toISOString();
    } else {
      guest.ospiti[payload.ospite].presenza = payload.presenza;
      guest.ospiti[payload.ospite].presenzaSetAt = new Date().toISOString();
    }
    try {
      await db
        .doc(`multiprojects/${project}/guests/${payload.email}`)
        .set(guest, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },
  togglePresenza: async ({ state }, payload) => {
    let guest = state.guests.find((guest) => guest.email === payload.email);
    if (payload.index === -1) {
      guest.presenza = !payload.presenza;
      guest.presenzaSetAt = new Date().toISOString();
    } else {
      guest.ospiti[payload.index].presenza = !payload.presenza;
      guest.ospiti[payload.index].presenzaSetAt = new Date().toISOString();
    }
    // console.log(guest);
    try {
      await db
        .doc(`multiprojects/${project}/guests/${guest.email}`)
        .set(guest, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },
  cancelGuest: async ({ state }, payload) => {
    let guest = state.guests.find((guest) => guest.email === payload.email);
    if (payload.index === -1) {
      guest.cancel = true;
      guest.cancelSetAt = new Date().toISOString();
    } else {
      guest.ospiti[payload.index].cancel = true;
      guest.ospiti[payload.index].cancelSetAt = new Date().toISOString();
    }
    try {
      await db
        .doc(`multiprojects/${project}/guests/${guest.email}`)
        .set(guest, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },
  updateGuest: async (state, data) => {
    try {
      await db
        .doc(`multiprojects/${project}/guests/${data.email}`)
        .set(data, { merge: true });
    } catch (error) {
      console.log(error);
    }
  },
  deleteGuest: async (state, email) => {
    try {
      await db.doc(`multiprojects/${project}/guests/${email}`).delete();
      await db.doc(`multiprojects/${project}/reserved/registered`).update({
        iscritti: remarr(email),
      });
    } catch (error) {
      console.log(error);
    }
  },

  saveDataTransaction: firestoreAction((context, payload) => {
    let primary = payload.primary;
    let people = "";
    payload.guests.forEach((guest, ind) => {
      people += `<li><b>Ospite ${ind + 1}:</b><br><b>${guest.nome} ${
        guest.cognome
      }</li>`;
    });

    let html = `<h4>Edenred's Club: Questo non è un party.</h4>
    < style="font-size:1rem">
    ${primary.nome} ${primary.cognome} hai completato la registrazione per l'evento "<b>Festa a Palazzo</b>".
    ${people}
</p>

        <p>
        Greetings, your Mutika team.</p>
        <hr>
        </p>`;
    let data = {
      sender: "segreteria@edenredclub.it",
      to: payload.primary.email,
      // bcc: "andrea.disanto@gmail.com",
      subject:
        "CONFERMA REGISTRAZIONE | EDENRED'S CLUB: QUESTO NON È UN PARTY | 06 NOVEMBRE 2024",
      text: `Email di conferma registrazione ad "Edenred's Club: Questo non è un party"`,
      html: html,
      replyTo: "segreteria@edenredclub.it",
    };
    db.runTransaction((transaction) => {
      return transaction
        .get(
          db
            .collection("multiprojects")
            .doc(payload.project)
            .collection("settings")
            .doc("settings")
        )
        .then((doc) => {
          if (!doc.exists) {
            throw "Document does not exist!";
          }
          if (doc.data().registrati.includes(primary.email)) {
            throw "Already registered";
          }
          {
            transaction.set(
              db
                .collection("multiprojects")
                .doc(payload.project)
                .collection("guests")
                .doc(payload.primary.email),
              data
            );
          }
        });
    })
      .then(() => {
        // Swal.fire({
        //   icon: "Congratulazioni!",
        //   title: "Registrazione confermata!",
        //   text: "Riceverai a breve una mail di conferma!",
        //   confirmButtonText: "Close",
        //   confirmButtonColor: "#fb0101",
        // }).then(() => {
        //   router.push(`/${payload.project}/confirmed`);
        // });
        router.push(`/${payload.project}/confirmed`);
      })
      .catch((error) => {
        if (error === "Already registered") {
          Swal.fire({
            title: "Attenzione!",
            html: `<p class="text-center">Hai già registrato la tua partecipazione!<br>Hai bisogno di supporto? Scrivi a &nbsp;<a href="mailto:segreteria@edenredclub.it?subject=Mail%20già%20registrata">segreteria@edenredclub.it</a></p>`,
            // footer: `<p class="text-center">già registrato</p>`,
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#fb0101",
            customClass: {
              confirmButton: "pill-button",
            },
            buttonsStyling: false,
          });
          return;
        }
        if (error === "Document does not exist!") {
          Swal.fire({
            title: "Errore!",
            text: "Errore di sistema. Riprova più tardi.",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#fb0101",
          });
          return;
        }
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#fb0101",
        });
      });
  }),
  iscriviMilano: async ({ dispatch }, payload) => {
    let data = {
      sender: "segreteria@edenredclub.it",
      to: payload.email.toLowerCase(),
      // bcc: "andrea.disanto@gmail.com",
      subject:
        "CONFERMA REGISTRAZIONE | EDENRED'S CLUB: QUESTO NON È UN PARTY | 06 NOVEMBRE 2024",
      text: `Email di conferma registrazione ad "Edenred's Club: Questo non è un party"`,
      // html: html,
      replyTo: "segreteria@edenredclub.it",
    };
    payload.data = data;
    dispatch("userInterface/startWorking", null, { root: true });
    iscriviMilano(payload)
      .then((res) => {
        console.log(res);
        dispatch("userInterface/stopWorking", null, { root: true });
        router.push(`/${project}/confirmed`);
      })
      .catch((error) => {
        dispatch("userInterface/stopWorking", null, { root: true });
        if (error.code === "functions/already-exists") {
          Swal.fire({
            title: "Attenzione!",
            html: `<p class="text-center">Hai già registrato la tua partecipazione!<br>Hai bisogno di supporto? Scrivi a &nbsp;<a href="mailto:segreteria@edenredclub.it?subject=Mail%20già%20registrata">segreteria@edenredclub.it</a></p>`,
            // footer: `<p class="text-center">già registrato</p>`,
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#fb0101",
            customClass: {
              confirmButton: "pill-button",
            },
          });
          return;
        }
        if (error === "Document does not exist!") {
          Swal.fire({
            title: "Errore!",
            text: "Errore di sistema. Riprova più tardi.",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#fb0101",
          });
          return;
        }
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#fb0101",
        });
      });
  },
  sendReminder: async ({ dispatch }, payload) => {
    let nominativo =
      payload.nome + " " + payload.cognome;
    let html = mailReminderHtml(nominativo);
    let data = {
      sender: "segreteria@edenredclub.it",
      recipient: payload.email.toLowerCase(),
      bcc: "",
      subject:
        "INFORMAZIONI UTILI | EDENRED'S CLUB: QUESTO NON È UN PARTY | 06 NOVEMBRE 2024",
      text: `Email di promemoria per l'evento "Edenred's Club: Questo non è un party"`,
      html: html,
      replyTo: "segreteria@edenredclub.it",
    };
    dispatch("userInterface/startWorking", null, { root: true });
    inviaMailHtml(data)
      .then((res) => {
        console.log(res.data.messaggio);
        dispatch("userInterface/stopWorking", null, { root: true });
      })
      .catch((error) => {
        dispatch("userInterface/stopWorking", null, { root: true });
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#fb0101",
        });
      });
  },
  sendAlert: async ({ dispatch }, payload) => {
    let html =
      "<p>A causa di viabilità ridotta, dovuta da una manifestazione che terminerà alle 19:30, è consigliato accedere alla location, Palazzo Colonna da Piazza della Pilotta 30</p>";
    let data = {
      sender: "segreteria@edenredclub.it",
      recipient: payload,
      bcc: "",
      subject: "Comunicazione importante | ACCESSO PALAZZO COLONNA",
      text: `A causa di viabilità ridotta, dovuta da una manifestazione che terminerà alle 19:30, è consigliato accedere alla location, Palazzo Colonna da Piazza della Pilotta 30`,
      html: html,
      replyTo: "segreteria@edenredclub.it",
    };
    dispatch("userInterface/startWorking", null, { root: true });
    inviaMailHtml(data)
      .then((res) => {
        console.log(res);
        dispatch("userInterface/stopWorking", null, { root: true });
      })
      .catch((error) => {
        dispatch("userInterface/stopWorking", null, { root: true });
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#fb0101",
        });
      });
  },
};
const getters = {
  getRole: (state) => {
    return state.role;
  },
  getSettings: (state) => {
    return state.settings;
  },
  allowed: (state) => {
    if (state.settings.allowed === undefined) {
      return [];
    }
    return state.settings.allowed;
  },
  getRecipients: (state) => {
    if (state.guests === undefined) {
      return [];
    }
    return state.guests.map((guest) => {
      return {
        email: guest.email,
        nome: guest.nome,
        cognome: guest.cognome,
      };
    });
  },
  getTestRecipients: () => {
    return [
      {
        email: "andrea.disanto@gmail.com",
        nome: "Andrea",
        cognome: "Di Santo",
      },
      {
        email: "it_group@mutika.it",
        nome: "Andrea",
        cognome: "Di Santo",
      },
      // {
      //   email: "Erika.FUSER@edenred.com",
      //   nome: "Erika",
      //   cognome: "Fuser",
      // },
      // {
      //   email: "Stefania.CHIANESE@edenred.com",
      //   nome: "Stefania",
      //   cognome: "Chianese",
      // },
      // {
      //   email: "Nicolo.NOVARINO@edenred.com",
      //   nome: "Nicolo",
      //   cognome: "Novarino",
      // },
      // {
      //   email: "Jenny.GIULIANI@edenred.com",
      //   nome: "Jenny",
      //   cognome: "Giuliani",
      // },
      // {
      //   email: "pietro.praiano@mutika.it",
      //   nome: "Pietro",
      //   cognome: "Praianò",
      // },
      // {
      //   email: "pietro.praiano@gmail.com",
      //   nome: "Pietro",
      //   cognome: "Praianò",
      // },
      // {
      //   email: "tea.ippolito@mutika.it",
      //   nome: "Tea",
      //   cognome: "Ippolito",
      // }
      // {
      //   email: "daniela.dagabito@mundys.com",
      //   nome: "Daniela",
      //   cognome: "D'Agabito",
      // },
      // { email: "Alessiayan0510@gmail.com", nome: "Ningning", cognome: "Yan" },
      // {
      //   email: "alessio.chialastri@un-industria.it",
      //   nome: "Alessio",
      //   cognome: "Chialastri",
      // },
      // {
      //   email: "s.dalisera@symphonieprime.com",
      //   nome: "Serena",
      //   cognome: "D'Alisera",
      // },
      // { email: "Sromeo@hertz.com", nome: "Simona", cognome: "Romeo" },
      // { email: "giulia.testa@edenred.com", nome: "Giulia", cognome: "Testa" },
      // {
      //   email: "valerio.favale@iblbanca.it",
      //   nome: "Valerio",
      //   cognome: "Favale",
      // },
      // {
      //   email: "cinzia.palmieri@edenred.com",
      //   nome: "Cinzia",
      //   cognome: "Palmieri",
      // },
      // {
      //   email: "francesca.bancale@edenred.com",
      //   nome: "Francesca",
      //   cognome: "Bancale",
      // },
    ];
  },
  getStats: (state) => {
    let stats = {
      invitati: 0,
      conOspiti: 0,
      accompagnatori: 0,
      totale: 0,
    };
    stats.invitati = state.guests.length;
    stats.conOspiti = state.guests.filter(
      (invitato) => invitato.ospiti.length > 0
    ).length;
    stats.accompagnatori = state.guests.reduce(
      (acc, invitato) => acc + invitato.ospiti.length,
      0
    );
    stats.totale = state.guests.reduce(
      (acc, invitato) => acc + invitato.ospiti.length + 1,
      0
    );
    return stats;
  },
  getGuests: (state) => {
    let ospiti = [];
    state.guests.forEach((guest) => {
      let people = toEdenredGuest(guest);
      people.forEach((person) => {
        ospiti.push(person);
      });
    });
    return ospiti;
  },
  getInvitati: (state) => {
    return state.guests.map((guest) => {
      return toEdenredInvitato(guest);
    });
  },
  getGlobal: (state) => {
    let ospiti = [];
    state.guests.forEach((guest) => {
      let people = toEdenredGlobal(guest);
      people.forEach((person) => {
        ospiti.push(person);
      });
    });
    return ospiti;
  },
  getOspitiLive: (state) => {
    let ospiti = [];
    let sortedGuests = [...state.guests].sort((a, b) =>
      (a.cognome + " " + a.nome).localeCompare(b.cognome + " " + b.nome)
    );
    sortedGuests.forEach((guest) => {
      // state.guests.forEach((guest) => {
      let people = toEdenredLive(guest);
      people.forEach((person) => {
        // if (!person.cancel) ospiti.push(person);
        ospiti.push(person);
      });
    });
    return ospiti;
  },
  getLive: (state, getters) => {
    // let ospiti = [];
    // let sortedGuests = [...state.guests].sort((a, b) =>
    //   (a.cognome + " " + a.nome).localeCompare(b.cognome + " " + b.nome)
    // );
    // sortedGuests.forEach((guest) => {
    //   let people = toEdenredLive(guest);
    //   people.forEach((person) => {
    //     if (!person.cancel) ospiti.push(person);
    //   });
    // });
    // return ospiti;
    return getters.getOspitiLive.filter((invitato) => !invitato.cancel);
  },
  getCancel: (state, getters) => {
    return getters.getOspitiLive.filter((invitato) => invitato.cancel);
  },
  getListaInvitati: (state, getters) => {
    let lista = [];
    getters.getInvitati.forEach((invitato) => {
      lista.push({
        email: invitato.email,
        finder: invitato.email + " | " + invitato.nome + " " + invitato.cognome,
      });
    });
    return lista;
  },
  getInvitatoByEmail: (state) => (email) => {
    let found = state.guests.find((invitato) => invitato.email === email);
    if (found === undefined) {
      return {};
    }
    return JSON.parse(JSON.stringify(found));
  },
  getRegistrati: (state) => {
    if (state.reserved.iscritti === undefined) {
      return [];
    }
    return state.reserved.iscritti;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
